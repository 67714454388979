import React from "react"
import Layout from "../../components/storyLayout"
import SEO from "../../components/seo"

const StoryPost = () => (
  <Layout storySrc="https://www.youtube.com/embed/SS7PLJjrRC0">
    <SEO title="Why Bible Groups? - Story" />
  </Layout>
)

export default StoryPost
